import Component from 'vue-class-component';
import { InvoiceType } from '@/models/InvoiceType';
import Vue from 'vue';
import { GridColumnProps, GridExpandChangeEvent, GridHeaderSelectionChangeEvent, GridSelectionChangeEvent } from '@progress/kendo-vue-grid';
import { invoiceService, loginHelper, organizationsServicePurchases, teamHelper } from '@/main';
import { Invoice } from '@/models/BrightInvoices/Models';
import moment from 'moment';
import { BModal } from 'bootstrap-vue';
import invoiceDetails from '@/components/invoice/invoice-detail.vue';
import { Watch } from 'vue-property-decorator';
import to from 'await-to-js';
import { SalesInvoice } from '@/models/BrightInvoices/Invoice';
import PageRender from '@/models/PageRender';
import { legalEntityModule } from '@/store/modules/LegalEntity';
import { CompositeFilterDescriptor, filterBy, orderBy } from '@progress/kendo-data-query';
import InvoiceStatus, { InvoiceStatusEnum } from '@/models/InvoiceStatus';
import PaymentStatus, { PaymentStatusEnum } from '@/models/PaymentStatus';
import sendInvoiceModalVue from '@/components/invoice/send-invoice-modal.vue';
import { saveExcel } from '@progress/kendo-vue-excel-export';
import { dateFormat } from '@vuejs-community/vue-filter-date-format';

@Component({
    components: {
        SendInvoiceModal: sendInvoiceModalVue,
    },
})
export default class SalesInvoicesPage extends PageRender {
    public type: InvoiceType = InvoiceType.Income;

    public allInvoices: SalesInvoice[] = [];
    public invoices: SalesInvoice[] = [];
    public invoiceNumber: string = '';
    public isReloadingGrid: boolean = false;

    public currentDate: string = moment().format('YYYY-MM');
    public filterMonth: string = null;
    public onlyFailures: boolean = false;
    public textSearch: string = null;

    public cellTemplate: any = invoiceDetails;
    public expandedItems: any[];
    public selectedProviderId: string = null;
    public selectedFilterProviderId: string = null;
    public direction: number = 1;
    public selectedAction: string = null;
    public selectedProviderActionId: string = null;
    public forceImportToProvider: boolean = false;

    public skip: number = 0;
    public take: number = 50;

    public get selectedInvoices() {
        return this.invoices.filter((x) => x.selected);
    }

    public get initialMonthFilters(): { fromFilterMonth: string; toFilterMonth: string } {
        const searchParams = sessionStorage.getItem('invoice-search');
        if (searchParams) {
            const params = JSON.parse(searchParams);
            return {
                fromFilterMonth: params.fromFilterMonth,
                toFilterMonth: params.toFilterMonth,
            };
        }

        return {
            fromFilterMonth: null,
            toFilterMonth: null,
        };
    }

    public $refs!: {
        sendInvoiceModal: BModal;
        paymentReceived: BModal;
        confirmCreditInvoiceModal: BModal;
        sendReminderModal: BModal;
    };

    public invoice: Invoice = null;
    public newInvoiceState: number = null;
    public newInvoiceStateObject: any = null;
    public isSales: boolean;
    public sort: any[] = [{ field: 'invoiceDate', dir: 'desc' }];
    public paymentStatusses = [
        { value: 1, text: 'Partly paid' },
        { value: 2, text: 'Fully paid' },
    ];

    public paymentStatus: any = { value: 2, text: 'Fully paid' };
    public paymentAmount: number = 0;
    public creditInvoiceDate = new Date();

    public filter: CompositeFilterDescriptor = {
        logic: 'and',
        filters: [],
    };

    public gridColumns: GridColumnProps[] = [
        { field: 'selected', width: 60, locked: true, filterable: false, cell: Vue.component('grid-checkbox') },
        { title: 'Description', field: 'invoiceDescription', width: 560, filterable: false },
        { field: 'invoiceDate', title: 'Invoice date', cell: this.renderDate, width: 130, filterable: false },
        { title: 'Due date', cell: this.renderDueDate, width: 125, filterable: false },
        { title: 'Amount', cell: this.renderInvoiceAmount, width: 150, filterable: false },
        { title: 'Status', field: 'status', filterCell: this.renderStatusFilter, width: 130 },
        { title: 'Payment status', cell: this.renderPaymentStatus, filterCell: this.renderPaymentStatusFilter, width: 130 },
        { title: 'Reminder sent?', cell: this.renderReminderStatus, width: 200, filterable: false },
        { title: 'Actions', cell: this.renderActions, width: 100, filterable: false },
    ];

    @Watch('textSearch')
    public onTextFilterChange(val, oldVal) {
        if ((val && val.length > 2) || (oldVal && oldVal.length > val.length)) {
            this.filterSalesInvoices();

            const searchParams = sessionStorage.getItem('invoice-search');
            if (searchParams) {
                const params = JSON.parse(searchParams);
                params.textSearch = val;
                sessionStorage.setItem('invoice-search', JSON.stringify(params));
            } else {
                sessionStorage.setItem('invoice-search', JSON.stringify({ fromFilterMonth: null, toFilterMonth: null, textSearch: val }));
            }
        }
    }

    public async mounted() {
        this.isSales = this.$route.meta.type === 'sales';

        if (loginHelper.isLoggedIn()) {
            await legalEntityModule.fetchIfNeeded();
            this.setLegalEntity();
        }

        if (this.isSales) {
            this.gridColumns.splice(1, 0, { title: 'Number', field: 'invoiceNumber', width: 90, filterable: false });
            this.gridColumns.splice(2, 0, {
                field: 'customer.name',
                title: 'Customer',
                width: 200,
                filterable: false,
                cell: this.renderCustomerName,
            });
        } else {
            this.gridColumns.splice(1, 0, { title: 'Reference', field: 'reference', width: 100, filterable: false });
            this.gridColumns.splice(2, 0, {
                field: 'customer.name',
                title: 'Supplier',
                width: 200,
                filterable: false,
                cell: this.renderCustomerName,
            });
        }

        this.$root.$on('bv::modal::hide', (bvEvent) => {
            if (bvEvent.trigger === 'backdrop' || bvEvent.trigger === 'headerclose') {
                this.selectedProviderId = this.selectedProviderActionId = this.selectedAction = null;
            }
        });

        this.isLoading = false;
    }

    public async loadInvoices(legalEntityId: string, fromDate: string, untilDate: string): Promise<SalesInvoice[]> {
        if (this.isSales) {
            return (this.invoices = await invoiceService.getSalesInvoices(legalEntityId, fromDate, untilDate));
        }

        return (this.invoices = (await invoiceService.getPurchaseInvoices(legalEntityId, fromDate, untilDate)).items);
    }

    public get total() {
        const invoices = filterBy(this.invoices, this.filter) as [];
        return invoices.length;
    }

    public get sortable() {
        return {
            allowUnsort: true,
            mode: 'multiple',
        };
    }

    public getData() {
        const invoices = filterBy(this.invoices, this.filter) as [];
        return orderBy(invoices, this.sort).slice(this.skip, this.take + this.skip);
    }

    public async createInvoice() {
        await this.$router.push({ name: 'create-invoice', params: this.$route.params });
    }

    public async reloadInvoices(dates = null, force: boolean = false) {
        this.invoices = [];
        this.isReloadingGrid = true;

        const searchParams = sessionStorage.getItem('invoice-search');
        if (searchParams && !dates) {
            const params = JSON.parse(searchParams);
            dates.fromFilterMonth = params.fromFilterMonth;
            dates.toFilterMonth = params.toFilterMonth;
        } else if (dates) {
            const params = {
                fromFilterMonth: dates.fromFilterMonth,
                toFilterMonth: dates.toFilterMonth,
                textSearch: this.textSearch,
            };

            sessionStorage.setItem('invoice-search', JSON.stringify(params));
        }

        if (dates && !force && dates.fromFilterMonth && dates.toFilterMonth) {
            const startDate = moment(dates.fromFilterMonth, 'YYYY-MM').add(-1, 'd');
            const endDate = moment(dates.toFilterMonth, 'YYYY-MM').add(1, 'M');
            this.invoices = this.allInvoices = await this.loadInvoices(
                legalEntityModule.current.legalEntityId,
                startDate.format('YYYY-MM-DD'),
                endDate.format('YYYY-MM-DD'),
            );
        } else {
            this.invoices = this.allInvoices = await this.loadInvoices(legalEntityModule.current.legalEntityId, null, null);
        }

        if (searchParams) {
            const params = JSON.parse(searchParams);
            this.textSearch = params.textSearch;
        }

        this.isReloadingGrid = false;
    }

    public async openSendInvoiceModal(item: SalesInvoice) {
        const invoice: SalesInvoice = await this.loadInvoice(item.invoiceId);

        this.$refs.sendInvoiceModal.open(invoice);
    }

    public async openSendReminderModal(item: SalesInvoice) {
        const invoice: SalesInvoice = await this.loadInvoice(item.invoiceId);
        this.$refs.sendReminderModal.open(invoice);
    }

    public openPaymentModal(item) {
        this.invoice = item;
        this.$refs.paymentReceived.show();
    }

    public async recievePayment() {
        let amount = this.paymentAmount;
        if (this.paymentStatus.value === 2) {
            amount = this.invoice.lines
                .map((l) => l.price * (l.quantity ? l.quantity : 1))
                .reduce((prev, cur) => {
                    return prev + cur;
                }, 0);

            if (this.invoice.amountPaid) {
                amount = amount - this.invoice.amountPaid;
            }
        }

        const success = await invoiceService.receivePayment(
            this.invoice.invoiceId,
            legalEntityModule.current.legalEntityId,
            this.paymentStatus.value === 2,
            amount,
        );

        if (success) {
            this.invoices.find((x) => x.invoiceId === this.invoice.invoiceId).paymentStatus = this.paymentStatus.value === 2 ? 'full' : 'partial';
            this.clearAndShowSuccess('REGISTER_PAYMENT_SUCCESS');
            this.$refs.paymentReceived.hide();
            this.invoice = null;
        }
    }

    public async exportBatch(aggregated: boolean = false) {
        this.showPending('Generating batch file..');
        const openInvoices = this.invoices.filter((x) => x.paymentStatus === PaymentStatusEnum.Open.toLowerCase());
        const organizationsAll = (await organizationsServicePurchases.getOrganizations()).items;
        const detailOrgs = [];

        for (let i = 0; i < openInvoices.length; i++) {
            const invoice = openInvoices[i];

            let foundOrg = detailOrgs.find((y) => y.organizationId === +invoice.customer.customerId);
            if (!foundOrg && invoice.customer.customerId) {
                foundOrg = await organizationsServicePurchases.getOrganization(invoice.customer.customerId);
                if (foundOrg) {
                    detailOrgs.push(foundOrg);
                    invoice.customer.iban = foundOrg.iban;
                    continue;
                }
            } else if (foundOrg) {
                invoice.customer.iban = foundOrg.iban;
            }

            if (!foundOrg) {
                foundOrg = organizationsAll.find(
                    (x) => x.name === invoice.customer.name || x.aliases.filter((x) => x.length > 0).indexOf(invoice.customer.name) > -1,
                );

                if (!foundOrg) {
                    continue;
                } else {
                    foundOrg = await organizationsServicePurchases.getOrganization(foundOrg.organizationId);
                    if (foundOrg) {
                        detailOrgs.push(foundOrg);
                        invoice.customer.iban = foundOrg.iban;
                    }
                }
            }
        }

        const batchItems = this.invoices
            .filter((x) => x.customer.iban)
            .map((x) => {
                return {
                    name: x.customer.name,
                    customer: {
                        IBAN: x.customer.iban,
                    },
                    amount: x.lines
                        .map((l) => l.price * (l.quantity ? l.quantity : 1) * (1 + (l.taxRate?.percentage ?? 0 / 100)))
                        .reduce((prev, cur) => {
                            return prev + cur;
                        }, 0),
                    reference: x.reference,
                };
            })
            .filter((x) => x.amount != 0);

        this.clearAndShowSuccess(`Batch file generated, ${batchItems.length} purchases met the conditions (IBAN present, amount not zero)`, 10000);

        if (aggregated) {
            const aggregatedBatchItems = [];
            const groupedProviders = this.groupBy(batchItems, 'name');
            for (let i = 0; i < groupedProviders.length; i++) {
                const group = groupedProviders[i];
                const total = group.map((x) => x.amount).reduce((prev, cur) => prev + cur, 0);

                const references = group.map((x) => x.reference).join(', ')
                aggregatedBatchItems.push({
                    name: group[0].name,
                    customer: {
                        IBAN: group[0].customer.IBAN,
                    },
                    amount: total,
                    reference: references.length > 140 ? 'Diverse facturen' : references,
                });
            }

            saveExcel({
                data: aggregatedBatchItems,
                fileName: `invoices-batch-file-export-aggregated-${legalEntityModule.current.legalEntityReference}-${moment().format(
                    'DD/MM/YYYY/hh:mm:ss',
                )}`,
                columns: [
                    { field: 'name', title: 'Naam begunstigde' },
                    { field: 'customer.IBAN', title: 'IBAN begunstigde' },
                    { field: 'amount', title: 'Bedrag' },
                    { field: 'reference', title: 'Omschrijving' },
                ],
            });

            return;
        }

        saveExcel({
            data: batchItems,
            fileName: `invoices-batch-file-export-ref-${legalEntityModule.current.legalEntityReference}-${moment().format('DD/MM/YYYY/hh:mm:ss')}`,
            columns: [
                { field: 'name', title: 'Naam begunstigde' },
                { field: 'customer.IBAN', title: 'IBAN begunstigde' },
                { field: 'amount', title: 'Bedrag' },
                { field: 'reference', title: 'Omschrijving' },
            ],
        });
    }

    public getStatusLabel(groupedProviders) {
        const successTotal = groupedProviders.filter((x) => x.status === 2).length;

        return successTotal > 0 ? 'warning' : 'danger';
    }

    public renderDate(h, _, row: { dataItem: Invoice }) {
        return h('td', moment(row.dataItem.invoiceDate, 'YYYY-MM-DD').format('DD-MM-YYYY'));
    }

    public renderCustomerName(h, _, row: { dataItem: Invoice }) {
        if (!row.dataItem.customer) {
            return h('td', 'N/A');
        }
        return h('td', row.dataItem.customer.name);
    }

    public renderPaymentStatus(h, _, row) {
        return h(
            'td',
            row.dataItem.paymentStatus === 'partial'
                ? `${row.dataItem.paymentStatus} (paid: ${row.dataItem.amountPaid})`
                : row.dataItem.paymentStatus,
        );
    }

    public renderReminderStatus(h, _, row) {
        return h('td', row.dataItem.dateReminded ? `Yes, on ${dateFormat(row.dataItem.dateReminded, 'HH:mm DD-MM-YYYY')}` : 'No');
    }

    public renderActions(h, _, row) {
        const status = row.dataItem.status;
        const actions = [];

        if (status !== 'draft') {
            if (this.isSales) {
                actions.push({ title: 'Credit invoice', function: this.creditInvoice });
                actions.push({ title: 'Send invoice', function: this.openSendInvoiceModal });
                actions.push({ title: 'Send reminder', function: this.openSendReminderModal });

                if (row.dataItem.paymentStatus === 'open' || row.dataItem.paymentStatus === 'partial') {
                    actions.push({ title: 'Register payment', function: this.openPaymentModal });
                }
            } else {
                actions.push({ title: 'Archive invoice', function: this.archiveInvoice });
            }
        } else {
            actions.push({ title: 'Book invoice', function: this.bookInvoice });
            actions.push({ title: 'Archive invoice', function: this.archiveInvoice });
        }

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public async bookInvoice(invoice: Invoice) {
        this.showPending('BOOKING_INVOICE_PENDING');
        await to(invoiceService.bookInvoice(invoice.invoiceId, legalEntityModule.current.legalEntityId, this.isSales));
        await this.reloadInvoices();
        this.clearAndShowSuccess('BOOKING_INVOICE_SUCCESS');
    }

    public async archiveInvoice(invoice: SalesInvoice) {
        this.showPending('ARCHIVE_INVOICE_PENDING');
        const success = await invoiceService.deleteInvoice(invoice, legalEntityModule.current.legalEntityId, this.isSales);
        if (!success) {
            return this.clearAndShowError('ARCHIVE_INVOICE_FAILED', null);
        }

        await this.reloadInvoices();
        this.clearAndShowSuccess('ARCHIVE_INVOICE_SUCCESS');
    }

    public async creditInvoice(invoice: Invoice) {
        this.invoice = invoice;
        this.creditInvoiceDate = new Date(invoice.invoiceDate);
        this.creditInvoiceDate.setHours(23, 59, 59);
        this.$refs.confirmCreditInvoiceModal.show();
    }

    public async confirmCreditInvoice() {
        this.showPending('CREDITING_INVOICE_PENDING');
        const success = await invoiceService.creditInvoice(
            this.invoice,
            legalEntityModule.current.legalEntityId,
            this.isSales,
            this.creditInvoiceDate,
        );

        if (!success) {
            return this.clearAndShowError('CREDITING_INVOICE_FAILED', null);
        }

        this.$refs.confirmCreditInvoiceModal.hide();
        await this.reloadInvoices();
        this.clearAndShowSuccess('CREDITING_INVOICE_SUCCESS');
    }

    public renderInvoiceAmount(h, _, row: { dataItem: Invoice }) {
        const total = row.dataItem.lines
            .map((l) => l.price * (l.quantity ? l.quantity : 1))
            .reduce((prev, cur) => {
                return prev + cur;
            }, 0);

        return h('td', total.toFixed(2));
    }

    public renderDueDate(h, _, row: { dataItem: SalesInvoice }) {
        const dueDate = row.dataItem.dueDate ? moment(row.dataItem.dueDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-';

        return h('td', dueDate);
    }

    public groupBy(arr, prop) {
        const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
        arr.forEach((obj) => map.get(obj[prop]).push(obj));
        return Array.from(map.values());
    }

    public expandChange(event: GridExpandChangeEvent) {
        event.event.stopPropagation();
        Vue.set(event.dataItem, event.target.$props.expandField, event.value);
    }

    public async onSelectionChange(event: GridSelectionChangeEvent) {
        Vue.set(event.dataItem, 'inEdit', false);
        Vue.set(event.dataItem, 'selected', !event.dataItem.selected);
    }

    public pageChangeHandler(event) {
        this.skip = event.page.skip;
        this.take = event.page.take;
    }

    public onHeaderSelectionChange(event: GridHeaderSelectionChangeEvent) {
        const checked = event.event.target.checked;
        Vue.set(
            this,
            'invoices',
            this.invoices.map((item) => ({ ...item, selected: checked })),
        );
    }

    public getTotal() {
        return this.invoices ? this.invoices.length : 0;
    }

    public filterSalesInvoices() {
        this.skip = 0;
        Vue.set(
            this,
            'allInvoices',
            this.allInvoices.map((item) => ({ ...item, expanded: false })),
        );

        let invoices = this.allInvoices;
        if (this.textSearch) {
            invoices = invoices.filter((invoice) => this.filterInvoicePropsOnText(invoice, this.textSearch));
        }

        this.invoices = invoices;
    }

    public async goToInvoice(row) {
        const routeName = this.isSales ? 'invoice-sales' : 'invoice-purchases';
        await this.$router.push({ name: routeName, params: { invoiceId: row.dataItem.invoiceId } });
    }

    public filterInvoicePropsOnText(invoice: SalesInvoice, text: string) {
        if (invoice.invoiceNumber && invoice.invoiceNumber.toString().indexOf(text) > -1) {
            return true;
        }

        if (invoice.invoiceDescription && invoice.invoiceDescription.toLowerCase().indexOf(text.toLowerCase()) > -1) {
            return true;
        }

        if (invoice.totalAmount && invoice.totalAmount.toString().indexOf(text) > -1) {
            return true;
        }

        if (invoice.reference && invoice.reference.toString().indexOf(text) > -1) {
            return true;
        }

        if (invoice.legalEntityId && invoice.legalEntityId.toString().indexOf(text) > -1) {
            return true;
        }

        if (invoice.customer && invoice.customer.name && invoice.customer.name.toLowerCase().indexOf(text.toLowerCase()) > -1) {
            return true;
        }

        if (invoice.lines) {
            for (let i = 0; i < invoice.lines.length; i++) {
                const line = invoice.lines[i];
                if (line.description && line.description.toLowerCase().indexOf(text.toLowerCase()) > -1) {
                    Vue.set(invoice, 'expanded', true);
                    return true;
                }

                if (line.price && line.price.toString().indexOf(text) > -1) {
                    Vue.set(invoice, 'expanded', true);
                    return true;
                }
            }
        }

        return false;
    }

    public setLegalEntity() {
        if (this.$route.params.legalEntity) {
            const legalEntity = legalEntityModule.find(this.$route.params.legalEntity);
            legalEntityModule.setCurrentLegalEntity(legalEntity);
        }
    }

    public sortChangeHandler(e) {
        this.sort = e.sort;
    }

    public filterChange(ev) {
        this.filter = ev.filter;
    }

    public async loadInvoice(invoiceId: string) {
        const [err, response] = await to(invoiceService.getInvoice(legalEntityModule.current.legalEntityId, invoiceId, this.isSales));
        if (err) {
            this.clearAndShowError('Failed to load legal entity details', err);
            return;
        }

        return new SalesInvoice(response);
    }

    private renderPaymentStatusFilter(h) {
        const props = {
            options: PaymentStatus.getStatussen(),
            filter: this.filter,
            field: 'paymentStatus',
            defaultSelected: [
                PaymentStatus.getStatus(PaymentStatusEnum.Full),
                PaymentStatus.getStatus(PaymentStatusEnum.Partial),
                PaymentStatus.getStatus(PaymentStatusEnum.Open),
            ],
        };

        return h(Vue.component('grid-filter-multi'), { props });
    }

    private renderStatusFilter(h) {
        const props = {
            options: InvoiceStatus.getStatussen(),
            filter: this.filter,
            field: 'status',
            defaultSelected: [InvoiceStatus.getStatus(InvoiceStatusEnum.Draft), InvoiceStatus.getStatus(InvoiceStatusEnum.Booked)],
        };

        return h(Vue.component('grid-filter-multi'), { props });
    }
}
